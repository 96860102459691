import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/services/business/auth/auth.service';
import { LikesDialogComponent } from '@modules/newsline/dialog/likes-dialog/likes-dialog.component';
import { FETCH_LIKES_SERVICE_TOKEN, FetchLikesService } from '@modules/newsline/dialog/likes-dialog/likes-provider';

@Component({
  selector: 'kp-likes',
  templateUrl: './likes.component.html',
  styleUrls: ['./likes.component.scss'],
})
export class LikesComponent implements OnInit {
  @Input() totalLikes = 0;
  @Input() itemId: number;
  @Input() isLiked = false;
  @Output() likeToggle = new EventEmitter<1 | -1>();

  likesCount: number;
  public postPreviewLikesArr: any[] = [];

  constructor(
    @Inject(FETCH_LIKES_SERVICE_TOKEN) private fetchLikesService: FetchLikesService,
    private viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    public authService: AuthService,
    public cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getPostPreviewLikes();
  }

  public get previewTotalLikes(): number {
    return this.likesCount - 4;
  }

  public openDialog(): void {
    this.dialog.open(LikesDialogComponent, {
      data: { itemId: this.itemId },
      viewContainerRef: this.viewContainerRef
    });
  }

  public getPostPreviewLikes(): void {
    this.fetchLikesService.getLikes(this.itemId, { limit: '4' }).subscribe((res): void => {
      this.likesCount = res.count;
      this.postPreviewLikesArr = res.results;
      this.cdr.markForCheck();
    });
  }

  public rate(): void {
    if (!this.isLiked) {
      this.likeToggle.emit(1);

      this.fetchLikesService.like(this.itemId).subscribe(() => {
        this.isLiked = true;

        if (this.totalLikes <= 4) {
          this.postPreviewLikesArr.push({
            avatar: this.authService.currentUser.profile.avatar,
            first_name: this.authService.currentUser.profile.first_name,
            id: this.authService.currentUser.profile.id,
            last_activity_time: this.authService.currentUser.profile.last_activity_time,
            last_name: this.authService.currentUser.profile.last_name,
          });
        }

        this.likesCount = this.totalLikes;
        this.cdr.markForCheck();
      });
    } else {
      this.likeToggle.emit(-1);

      this.fetchLikesService.unlike(this.itemId).subscribe(() => {
        this.isLiked = false;

        if (this.totalLikes <= 4) {
          if (this.postPreviewLikesArr.find((el) => el.id === this.authService.currentUser.id)) {
            this.postPreviewLikesArr.pop();
          }
        }

        this.likesCount = this.totalLikes;
        this.cdr.markForCheck();
      });
    }
  }
}
