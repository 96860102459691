import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QueryParams } from '@shared/models/query-params.model';
import { FETCH_LIKES_SERVICE_TOKEN, FetchLikesService } from './likes-provider';

@Component({
  selector: 'kp-likes-dialog',
  templateUrl: './likes-dialog.component.html',
  styleUrls: ['./likes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikesDialogComponent implements OnInit {
  public previewLikesArr: any[] = [];
  public likesCount: number;

  private params: QueryParams = {
    limit: 20,
    offset: 0,
  };

  constructor(
    @Inject(FETCH_LIKES_SERVICE_TOKEN) private fetchLikesService: FetchLikesService,
    @Inject(MAT_DIALOG_DATA) private data: { itemId: number },
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._getPreviewLikes();
  }

  public onScroll(): void {
    const likedUsersCount: number = this.likesCount;

    if (
      likedUsersCount > this.params.offset &&
      likedUsersCount > (this.params.offset as number) + (this.params.limit as number)
    ) {
      this.params.offset = (this.params.offset as number) + 20;
      this._getPreviewLikes();
    }
  }

  private _getPreviewLikes(): void {
    this.fetchLikesService.getLikes(this.data.itemId, this.params).subscribe(({ count, results }): void => {
      this.likesCount = count;
      this.previewLikesArr = [...this.previewLikesArr, ...results];
      this.cdr.markForCheck();
    });
  }
}
