export enum UserMenuOptionViewName {
  Profile = 'Профиль',
  Settings = 'Настройки',
  Shop = 'Магазин',
  CuratorChat = 'Чат с менеджером',
  DownloadApp = 'Скачать приложение',
  Logout = 'Выйти из профиля',
}

export enum UserRoleGroupName {
  Admins = 'Администраторы',
  Members = 'Участники клуба',
  ChatManagers = 'Менеджеры чатов',
  Curators = 'Кураторы',
  Employees = 'Сотрудники клуба',
  EventManagers = 'Менеджеры мероприятий',
  MeetingManagers = 'Менеджеры встреч',
  ForumManagers = 'Форум менеджеры',
  ForumModerators = 'Форум модераторы',
  Candidates = 'Кандидаты',
  NotificationManagers = 'Менеджеры уведомлений',
  Hostess = 'Хостес',
  BookingAdmins = 'Администраторы бронирований',
}

export enum UserRoleGroupId {
  NewslineManagers = 12,
}
