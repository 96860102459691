import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMemberGratsDto } from '@shared/interfaces/members/members-grats.interface';
import { IMemberHobbyDto } from '@shared/interfaces/members/members-hobby.interface';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { IUserStatusInClub, IUserWithProfileDto } from '@shared/models/user/dto/user-dto.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { map } from 'rxjs/operators';
import { prepareAndDownloadFileWithServerData } from '@shared/utils/prepare-and-download-file-with-server-data';

@Injectable({
  providedIn: 'root',
})
export class MembersUserHttpService {
  private readonly _apiPath = '/members/users/';
  private readonly _apiHobbyPath = '/members/hobby/';
  private readonly _apiStatusInClub = '/members/status-in-club/';
  private readonly _apiMembersGrats = '/members/gratitudes/';
  private readonly _apiTagsPath = '/members/tags/all';

  constructor(private http: HttpService) {}

  getUsers(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IUserWithProfileDto>> {
    return this.http.get<ArrayPayload<IUserWithProfileDto>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getUserById(id: number): Observable<IUserWithProfileDto> {
    return this.http.get<IUserWithProfileDto>(`${this._apiPath}${id}/`);
  }

  createUser(user: Partial<IUserWithProfileDto>): Observable<IUserWithProfileDto> {
    return this.http.post<IUserWithProfileDto>(this._apiPath, user);
  }

  updateUserById(id: number, user: Partial<IUserWithProfileDto> | FormData): Observable<IUserWithProfileDto> {
    return this.http.put<IUserWithProfileDto>(`${this._apiPath}${id}/`, user);
  }

  patchUserById(id: number, user: Partial<IUserWithProfileDto> | FormData): Observable<IUserWithProfileDto> {
    return this.http.patch<IUserWithProfileDto>(`${this._apiPath}${id}/`, user);
  }

  deleteUserById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }

  activateUserById(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/activate/`, null);
  }

  getMembersHobbies(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IMemberHobbyDto>> {
    return this.http.get<ArrayPayload<IMemberHobbyDto>>(this._apiHobbyPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getMembersStatusInClub(
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<ArrayPayload<IUserStatusInClub>> {
    return this.http.get<ArrayPayload<IUserStatusInClub>>(this._apiStatusInClub, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  // Динамическая ссылка на профиль пользователя
  createDynamicLinkForProfile(id: number): Observable<any> {
    return this.http.get(`${this._apiPath}${id}/dynamic-link/`);
  }

  // Получение благодарностей юзера
  getUserGrats(
    id: number,
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<ArrayPayload<IMemberGratsDto>> {
    return this.http.get<ArrayPayload<IMemberGratsDto>>(`${this._apiPath}${id}/gratitudes/`, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  // Добавление благодарности
  postUserGrat(id: number, gratitude: { text: string }): Observable<ArrayPayload<{ text: string }>> {
    return this.http.post<ArrayPayload<IMemberGratsDto>>(`${this._apiPath}${id}/gratitudes/`, gratitude);
  }

  // Редактирование благодарности
  public patchUserGrat(id: number, gratitude: { text: string }): Observable<ArrayPayload<{ text: string }>> {
    return this.http.patch<ArrayPayload<IMemberGratsDto>>(`${this._apiMembersGrats}${id}/`, gratitude);
  }

  // Удаление благодарности
  public deleteUserGrat(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiMembersGrats}${id}/`);
  }

  public getUserPrivateCalendar(id: number): Observable<Blob> {
    return this.http
      .get<HttpResponse<Blob>>(`${this._apiPath}${id}/event-history/`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map((response: HttpResponse<Blob>) => prepareAndDownloadFileWithServerData(response, 'user_calendar')));
  }

  public getAllTags(params?: KeyValue<string>): Observable<any> {
    return this.http.get(`${this._apiTagsPath}`, { params: params && new HttpParams({ fromObject: params }) });
  }
}
