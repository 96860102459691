import { INewslinePostComment } from '@shared/models/newsline/view/newsline-post-comment.model';

export interface INewsLinePostCommentView extends INewslinePostComment {
  newResponseText?: string | null;
  showMore?: boolean;
}

export enum SortingOptions {
  'interest_rate' = 'interest_rate',
  'created' = '-created',
  'popularity_rate' = 'popularity_rate',
}

export const sortingOptions: SortingOptions[] = [
  SortingOptions.interest_rate,
  SortingOptions.created,
  SortingOptions.popularity_rate,
];

export const sortingOptionsLabels: Record<SortingOptions, string> = {
  interest_rate: 'Сначала интересные',
  '-created': 'Сначала новые',
  popularity_rate: 'Сначала популярные',
};


export const DefaultResponsesLimit = 15;
