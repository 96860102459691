import { Injectable } from '@angular/core';
import { IChat } from '@shared/models/messages/view/chat.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { isNil } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { ChatHttpService } from '@core/services/api/messages/chat-http.service';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { IChatDto } from '@shared/models/messages/dto/chat-dto.model';
import {
  ChatCurrentUserMembershipState,
  ChatParticipantRelatedFieldKey,
  ChatRelatedFieldKey,
  ChatTypeKey,
} from '@modules/chats/enums/keys.enum';
import { CacheService } from '../utils/cache.service';
import { ChatUtilsService } from './chat-utils.service';
import {
  IChatParticipant,
  IChatParticipantShortDto,
  IChatParticipantsShortInfo,
} from '@shared/models/messages/dto/chat-participant-dto.model';
import { CommonFilterKey } from '@shared/enums/keys.enum';
import { QueryParams } from '@shared/models/query-params.model';
import * as moment from 'moment';
import { CHAT_MESSAGE_DRAFTS_KEY_LOCAL } from '@shared/constants/storage-keys/chat-message-drafts-key.local';
import { IChatMessageDto } from '@shared/models/messages/dto/chat-message-dto.model';
import { AuthService } from '@core/services/business/auth/auth.service';
import { Router } from '@angular/router';
import { IMemberOnlineInfo } from '@core/services/business/members/members-online.service';
import { SKIP_ERROR_INTERCEPTOR_HEADER } from '@core/interceptors/http-error.interceptor';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

const CHAT_PARTICIPANTS_LIMIT: number = 500;
export const DEFAULT_PORTION_SIZE: number = 50;

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public chats: IChat[] = [];
  public pinnedChatsCount: number = 0;
  public unpinnedChatsCount: number = 0;
  public isListChatsLoading: boolean = false;

  public readonly currentChatState$$: BehaviorSubject<IChat | null> = new BehaviorSubject<IChat | null>(null);
  public readonly currentChat$: Observable<IChat | null> = this.currentChatState$$.asObservable();

  public readonly isFakeChat$: Observable<boolean> = this.currentChat$.pipe(
    map((currentChat?: IChat) => currentChat.id === -1),
  );

  private readonly isCurrentChatLoadingState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public readonly isCurrentChatLoading$: Observable<boolean> = this.isCurrentChatLoadingState$.asObservable();

  public readonly updateChatExternalTrigger$: Subject<number> = new Subject<number>();

  private readonly currentChatParticipantsState$: ReplaySubject<IChatParticipant[]> = new ReplaySubject<
    IChatParticipant[]
  >(1);
  public readonly currentChatParticipants$: Observable<IChatParticipant[]> =
    this.currentChatParticipantsState$.asObservable();

  private readonly currentChatParticipantsUserInfoState$: BehaviorSubject<IChatParticipantsShortInfo> =
    new BehaviorSubject<IChatParticipantsShortInfo>({});
  public readonly currentChatParticipantsUserInfo$: Observable<IChatParticipantsShortInfo> =
    this.currentChatParticipantsUserInfoState$.asObservable();

  private readonly chatMessageDraftMapState$: BehaviorSubject<Map<number, Partial<IChatMessageDto>>> =
    new BehaviorSubject<Map<number, Partial<IChatMessageDto>>>(new Map([]));
  public readonly chatMessageDraftMap$: Observable<Map<number, Partial<IChatMessageDto>>> =
    this.chatMessageDraftMapState$.asObservable();

  public readonly isUserParticipantInCurrentChat$: Observable<boolean> = this.currentChat$.pipe(
    filter((currentChat: IChat) => !!currentChat),
    map((currentChat: IChat) => currentChat.participant_info.state === ChatCurrentUserMembershipState.Active),
  );

  public readonly currentChatParticipantOnlineInfo$: ReplaySubject<IMemberOnlineInfo> =
    new ReplaySubject<IMemberOnlineInfo>(1);

  private readonly chatUnreadCounterState$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly chatUnreadCounter$: Observable<number> = this.chatUnreadCounterState$.asObservable();

  private readonly isAdditionalMenuOpenState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isAdditionalMenuOpen$: Observable<boolean> = this.isAdditionalMenuOpenState$.asObservable();

  private readonly isSettingsMenuOpenState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isSettingsMenuOpen$: Observable<boolean> = this.isSettingsMenuOpenState$.asObservable();

  constructor(
    private chatHttpService: ChatHttpService,
    private chatUtilsService: ChatUtilsService,
    private authService: AuthService,
    private readonly router: Router,
    private cacheService: CacheService,
    private readonly snackBar: MatSnackBar,
  ) {}

  public get currentChatId(): number {
    return this.currentChatState$$.value?.id;
  }

  public get currentChat(): IChat | null {
    return this.currentChatState$$.value;
  }

  public get pinnedChats(): IChat[] {
    return this.chats?.length ? this.chats.filter((chat: IChat) => chat.participant_info.pinned) : [];
  }

  public get unpinnedChats(): IChat[] {
    return this.chats?.length ? this.chats.filter((chat: IChat) => !chat.participant_info.pinned) : [];
  }

  public setCurrentChatUnreadCounterState(counterValue: number): void {
    this.chatUnreadCounterState$.next(counterValue);
  }

  public setCurrentChatState(newCurrentChat: IChat | null): void {
    if (isNil(newCurrentChat)) {
      this.isAdditionalMenuOpenState$.next(false);
    }

    this.currentChatState$$.next(newCurrentChat);
    if (newCurrentChat) {
      this.setCurrentChatUnreadCounterState(newCurrentChat.participant_info.unread_messages_count);
    }
    this.setIsChatByIdLoading(false);
  }

  public setChatUnreadCounterStateById(chatId: number, counterValue: number): void {
    if (!chatId) {
      return;
    }
    const targetChatIdx: number = this.getChatIndexInExistChats(chatId);

    if (targetChatIdx === -1) {
      return;
    }

    const targetChat: IChat = this.chats[targetChatIdx];

    if (chatId === this.currentChatId) {
      this.setCurrentChatUnreadCounterState(counterValue);
    }
    targetChat.participant_info.unread_messages_count = counterValue;
    this.updateChatExternalTrigger$.next(chatId);
  }

  public setChatMessageDraftMapState(chatMessageDraftMap: Map<number, Partial<IChatMessageDto>>): void {
    this.chatMessageDraftMapState$.next(chatMessageDraftMap);
    localStorage.setItem(CHAT_MESSAGE_DRAFTS_KEY_LOCAL, JSON.stringify(Array.from(chatMessageDraftMap.entries())));
  }

  public setInitialChatMessageDraftMapState(): void {
    const localChatMessageDrafts: string = localStorage.getItem(CHAT_MESSAGE_DRAFTS_KEY_LOCAL);
    this.setChatMessageDraftMapState(new Map(localChatMessageDrafts ? JSON.parse(localChatMessageDrafts) : []));
  }

  public getChatIndexInExistChats(chatId: number): number {
    if (!chatId || !this.chats?.length) {
      return -1;
    }

    return this.chats?.findIndex((chat: IChat) => chat.id === chatId);
  }

  public setIsAdditionalMenuOpenState(isOpen: boolean): void {
    this.isAdditionalMenuOpenState$.next(isOpen);
  }

  public setIsSettingsMenuOpen(isOpen: boolean): void {
    this.isSettingsMenuOpenState$.next(isOpen);
  }

  public setIsChatByIdLoading(isLoading: boolean): void {
    this.isCurrentChatLoadingState$.next(isLoading);
  }

  public setCurrentChatParticipantsState(participants: IChatParticipant[]): void {
    this.currentChatParticipantsState$.next(participants);
    this.currentChatParticipantsUserInfoState$.next(this._prepareCurrentChatParticipantsUserInfo(participants));
  }

  public openCurrentChatAdditionalMenu(): void {
    this.setIsAdditionalMenuOpenState(true);
  }

  public openEditSettingsChat(): void {
    this.setIsSettingsMenuOpen(true);
  }

  public closeEditSettingsChat(): void {
    this.setIsSettingsMenuOpen(false);
  }

  public getDialogByUserId(userId: number): Observable<IChat> {
    return this.getChats({ type: ChatTypeKey.Dialog, participants: userId }).pipe(
      map((response: ArrayPayload<IChat>) => (response.results.length ? response.results[0] : null)),
    );
  }

  public getChats(params: QueryParams): Observable<ArrayPayload<IChat>> {
    return this.chatHttpService.getList(params).pipe(
      take(1),
      map((response: ArrayPayload<IChatDto>) => {
        return {
          results: response.results.map((chat: IChatDto) => this.chatUtilsService.transformChatDtoToChatView(chat)),
          count: response.count,
        };
      }),
    );
  }

  // Получение участников чата по id чата
  public getChatParticipantsByChatId(
    chatId: number,
    participantsCount: number,
  ): Observable<ArrayPayload<IChatParticipant>> {
    const limit: number = participantsCount < CHAT_PARTICIPANTS_LIMIT ? participantsCount : CHAT_PARTICIPANTS_LIMIT;

    return this.chatHttpService.getChatParticipantsById({ chat: chatId, limit }).pipe(
      take(1),
      map((response: ArrayPayload<IChatParticipant>) => {
        this.setCurrentChatParticipantsState(response.results);
        return response;
      }),
    );
  }

  public getPinnedChats(): Observable<ArrayPayload<IChat>> {
    // Параметры пагинации не прокидываются, т.к. по системным требованиям pinned-чатов не может быть больше 3
    const pinnedParams: QueryParams = {
      [ChatParticipantRelatedFieldKey.Pinned]: true,
      [CommonFilterKey.Ordering]: '-pinned_time',
      has_participation: true,
    };

    return this.getChats({ ...pinnedParams });
  }

  public getUnpinnedChats(paginationParams: QueryParams): Observable<ArrayPayload<IChat>> {
    const pinnedParams: QueryParams = {
      [ChatParticipantRelatedFieldKey.Pinned]: false,
      [CommonFilterKey.Ordering]: '-last_msg_date',
      has_participation: true,
    };

    return this.getChats({ ...pinnedParams, ...paginationParams });
  }

  public getChatById(chatId: number): Observable<IChat> {
    this.setIsChatByIdLoading(true);

    return this.chatHttpService.getById(chatId).pipe(
      this.cacheService.cache(['get-chat', chatId.toString()]),
      take(1),
      map((chatDto: IChatDto) => this.chatUtilsService.transformChatDtoToChatView(chatDto)),
    );
  }

  public reloadChat() {
    return this.getChatById(this.currentChatId).pipe(
      tap((chat) => {
        this.setCurrentChatState(chat);
      }),
    );
  }

  public createChat(chat: Partial<IChatDto>): Observable<IChat> {
    return this.chatHttpService.create(chat).pipe(
      map((chatDto: IChatDto) => this.chatUtilsService.transformChatDtoToChatView(chatDto)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.snackBar.open(error.error?.detail, 'Закрыть', {
            duration: 5_000,
            panelClass: ['snack-bar-container-custom', 'snack-bar-errors-messages'],
          });
        }

        return throwError(error);
      }),
    );
  }

  public updateChat(chatId: number, chat?: Partial<IChatDto> | FormData): Observable<IChat> {
    return this.chatHttpService
      .patchById(chatId, chat)
      .pipe(map((chatDto: IChatDto) => this.chatUtilsService.transformChatDtoToChatView(chatDto)));
  }

  public updateChatAvatar(chatId: number, avatar: File): Observable<IChatDto> {
    const avatarFormData: FormData = new FormData();
    avatarFormData.append(ChatRelatedFieldKey.Avatar, avatar);

    return this.chatHttpService.patchById(chatId, avatarFormData, {
      observe: 'events',
      reportProgress: true,
      headers: SKIP_ERROR_INTERCEPTOR_HEADER,
    });
  }

  public updateChatWithRefresh(chatId: number, chat?: Partial<IChatDto> | FormData): Observable<IChat> {
    return this.updateChat(chatId, chat).pipe(tap((chat) => this.setCurrentChatState(chat)));
  }

  public deleteChatByIdAndRemoveFromTargetChats(chat: IChat, isDeleteForAll?: boolean): Observable<void> {
    chat.isDeleting = true;

    const params: QueryParams = { chats: [chat.id] };

    if (isDeleteForAll) {
      params.for_all = true;
    }

    return this.chatHttpService.deleteById(chat.id, params).pipe(
      take(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.snackBar.open(error.error?.detail, 'Close', {
            duration: 5_000,
            panelClass: ['snack-bar-container-custom', 'snack-bar-errors-messages'],
          });
        }

        return throwError(error);
      }),
    );
  }

  public clearChatHistory(chatId: number): Observable<void> {
    return this.chatHttpService.clearHistory(chatId).pipe(take(1));
  }

  public addParticipants(chatId: number, participants: IChatParticipantShortDto[]): Observable<void> {
    return this.chatHttpService.addParticipants(chatId, { participants }).pipe(take(1));
  }

  // Удалить участника из чата
  public deleteParticipant(chatId: number, participants: IChatParticipantShortDto[]): Observable<void> {
    return this.chatHttpService.deleteParticipant(chatId, { participants }).pipe(take(1));
  }

  //Предоставляет пользователю права администратора
  public makeChatParticipantAdmin(chatId: number, userId: number): Observable<void> {
    return this.chatHttpService.chatParticipantActivateAdmin(chatId, userId).pipe(take(1));
  }

  //Забирает у пользователя права администратора
  public pickUpChatParticipantAdmin(chatId: number, userId: number): Observable<void> {
    return this.chatHttpService.chatParticipantDeactivateAdmin(chatId, userId).pipe(take(1));
  }

  // Пересылка сообщений в чаты
  public postForwardMessagesInChat(
    messagesIds: number[],
    sourceChatId: number,
    destinationChatIds: number[],
  ): Observable<void> {
    return this.chatHttpService.postForwardMessagesInChat(messagesIds, sourceChatId, destinationChatIds);
  }

  // Закрепить чат
  public pinChat(chat: IChat, index: number): Observable<IChat> {
    this._pinChatLocally(chat, index);

    return this.updateChat(chat.id, { participant_info: { pinned: true } });
  }

  // Открепить чат
  public unpinChat(chat: IChat, index: number): Observable<IChat> {
    this._unpinMessageLocally(chat, index);

    return this.updateChat(chat.id, { participant_info: { pinned: false } });
  }

  public toggleMuteChatState(chat: IChat): Observable<IChat> {
    this._toggleMuteChatStateLocally(chat);

    return this.updateChat(chat.id, { participant_info: { muted: chat.participant_info.muted } });
  }

  // Отправка события, что пользователю доставлено сообщение
  public messageDeliveredToRecipient(chatIds: number[]): Observable<void> {
    return this.chatHttpService.messageDeliveredToRecipient(chatIds);
  }

  public markChatAsUnreadByChatId(chatId: number): Observable<void> {
    return this.chatHttpService.markChatAsUnread(chatId);
  }

  public initOnNewChatOpened(newChat: IChat): void {
    this.setIsAdditionalMenuOpenState(false);

    if (!newChat.participant_info?.unread_messages_count && !newChat.participant_info.is_read) {
      this.chatHttpService.readAllMessagesInChatById(newChat.id).subscribe();
    }
  }

  public openParticipantProfile(): void {
    this.isFakeChat$
      .pipe(take(1))
      .subscribe((isFakeChat: boolean) =>
        isFakeChat ? this._openFakeChatParticipant() : this._openRealChatParticipant(),
      );
  }

  public postSharedMessageInChats(data: any): Observable<void> {
    return this.chatHttpService.postSharedMessageInChats(data);
  }

  private _openFakeChatParticipant(): void {
    this.currentChat$
      .pipe(
        take(1),
        filter((currentChat: IChat) => !!currentChat.participantId),
      )
      .subscribe((currentChat: IChat) => this._navigateToProfile(currentChat.participantId));
  }

  private _openRealChatParticipant(): void {
    this.currentChatParticipants$.pipe(take(1)).subscribe((participantsInfo: IChatParticipant[]) => {
      const targetParticipant: IChatParticipant | undefined = participantsInfo.find(
        (participantInfo: IChatParticipant) => participantInfo.user?.id !== this.authService.currentUser.id,
      );

      if (targetParticipant && targetParticipant.user?.id) {
        this._navigateToProfile(targetParticipant.user.id);
      }
    });
  }

  private _navigateToProfile(profileId: unknown): void {
    this.router.navigate([`profile/${profileId}`]);
  }

  private _prepareCurrentChatParticipantsUserInfo(participants: IChatParticipant[]): IChatParticipantsShortInfo {
    const participantsInfo: IChatParticipantsShortInfo = {};
    participants.forEach((participant: IChatParticipant) => (participantsInfo[participant.user.id] = participant.user));

    return participantsInfo;
  }

  private _pinChatLocally(chat: IChat, index: number): void {
    this.chats.splice(index, 1);
    chat.participant_info.pinned = true;
    this.pinnedChatsCount += 1;
    this.chats.unshift(chat);
  }

  private _unpinMessageLocally(chat: IChat, index: number): void {
    this.chats.splice(index, 1);
    this.pinnedChatsCount -= 1;

    const targetIndexToPaste: number = this.unpinnedChats.findIndex(
      (existChat: IChat) =>
        existChat.participant_info.last_message &&
        moment(chat.participant_info.last_message.created).isAfter(
          moment(existChat.participant_info.last_message.created),
        ),
    );
    if (targetIndexToPaste) {
      this.chats.splice(targetIndexToPaste + this.pinnedChatsCount, 0, chat);
    }
  }

  private _toggleMuteChatStateLocally(chat: IChat): void {
    chat.participant_info.muted = !chat.participant_info.muted;

    if (this.currentChatId === chat.id) {
      const participant_info = {
        ...this.currentChatState$$.value.participant_info,
        muted: chat.participant_info.muted,
      };
      this.setCurrentChatState({ ...this.currentChatState$$.value, participant_info });
    }
  }
}
